import { INavData } from "@coreui/angular";

export const navItems: INavData[] = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "Home",
    },
  },
  {
    name: "Masters",
    url: "/masters",
    icon: "icon-puzzle",
    children: [
      {
        name: "Client",
        url: "/masters/client",
        icon: "icon-people",
      },
      {
        name: "Vendors",
        url: "/masters/vendors",
        icon: "icon-plane",
      },
      {
        name: "Item",
        url: "/masters/item",
        icon: "icon-basket-loaded",
      },
      {
        name: "ItemCategory",
        url: "/masters/itemcategory",
        icon: "icon-basket-loaded",
      },
      {
        name: "Country",
        url: "/masters/country",
        icon: "icon-plane",
      },
      {
        name: "Branch",
        url: "/masters/branch",
        icon: "icon-plane",
      },
    ],
  },
  {
    name: "User Management",
    url: "/usermanagement",
    icon: "icon-puzzle",
    children: [
      {
        name: "Users",
        url: "/usermanagement/users",
        icon: "icon-people",
      },
      {
        name: "User Group",
        url: "/usermanagement/usergroup",
        icon: "icon-people",
      }
    ],
  },
  {
    name: "HR",
    url: "/humanresource",
    icon: "icon-puzzle",
    children: [
      {
        name: "Employee",
        url: "/humanresource/employee",
        icon: "icon-people",
      },
      {
        name: "Job Component",
        url: "/humanresource/jobComponent",
        icon: "icon-people",
      },
      {
        name: "Job Offer Letter",
        url: "/humanresource/jobOfferLetter",
        icon: "icon-people",
      },
      {

        name: "Employee Vacation",
        url: "/humanresource/Employee Vacation",
        icon: "icon-people",
      },
      {
        name: "Employee Departure",
        url: "/humanresource/Employee Departure",
        icon: "icon-people",
      }
    ],
  },
  {
    name: "Sales",
    url: "/sales",
    icon: "icon-puzzle",
    children: [
      {
        name: "Customer Enquiry",
        url: "/sales/customer-enquiry",
        icon: "icon-people",
      },
      {
        name: "Quotation",
        url: "/sales/quotation",
        icon: "icon-people",
      },
      {
        name: "Order",
        url: "/sales/order",
        icon: "icon-people",
      },
      
      {
        name: "Service Order",
        url: "/sales/service-order",
        icon: "icon-people",
      },
      {
        name: "Invoice",
        url: "/sales/invoice",
        icon: "icon-people",
      },
      {
        name: "MRF",
        url: "/sales/MRF",
        icon: "icon-people",
      },
      {
        name: "Receipt",
        url: "/sales/receipt",
        icon: "icon-people",
      },
      
    ],
  },
  {
    name: "Profile",
    url: "/pages",
    icon: "icon-settings",
    children: [
      {
        name: "Login",
        url: "/login",
        icon: "icon-login",
      },
      {
        name: "Register",
        url: "/register",
        icon: "icon-logout",
      },
      {
        name: "Error 404",
        url: "/404",
        icon: "icon-star",
      },
      {
        name: "Error 500",
        url: "/500",
        icon: "icon-star",
      },
    ],
  },
  {
    name: "Account",
    url: "/account",
    icon: "icon-settings",
    children: [
      {
        name: "Bank",
        url: "/account/bank",
        icon: "icon-login",
      }
    ],
  },
{
    name: "Administration",
    url: "/administration",
    icon: "icon-puzzle",
    children: [
      {
        name: "Purchase Request",
        url: "/administration/purchaserequest",
        icon: "icon-people",
      },
      {
        name: "PurchaseOrder",
        url: "/administration/purchaseorder",
        icon: "icon-people",
      },
      // {
      //   name: "Job Offer Letter",
      //   url: "/humanresource/jobOfferLetter",
      //   icon: "icon-people",
      // },

    ],
  },
];
