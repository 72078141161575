import { Pipe, PipeTransform,NgModule } from "@angular/core";
import {ActFilter,FilterColumn} from '../models/filter.models'

@Pipe({
  name: "myfilter",
  pure: false,
})
export class myfilter implements PipeTransform {
  transform(items: any[], filter: any,defaultFilter:any, filterCols:Array<ActFilter>): any {
    if (!items || (!filter && filterCols.length==0) ) {
      return items;
    }

    console.log(items);
    console.log(filter.value);

    if (filter.value != "") {
      items = items.filter(
        (item) => item[defaultFilter].toString().indexOf(filter) !== -1
      );
    }
    
   

    for(let i=0;i<filterCols.length;i++)
    {
      if(filterCols[i].FilterColumn.includes('.'))
      {
        let SplitStr=filterCols[i].FilterColumn.split('.');
        switch(filterCols[i].FilterType)
        {
          case 'Contains':
             items = items.filter(
              (item) => item[SplitStr[0]][SplitStr[1]].toString().indexOf(filterCols[i].FilterText) !== -1
            ).slice();
            break;
          case 'Equals':
            items = items.filter(
              (item) => item[SplitStr[0]][SplitStr[1]].toString()==filterCols[i].FilterText.toString()
            );
            break;
        }
      }
      else
      {
        switch(filterCols[i].FilterType)
        {
          case 'Contains':
            items = items.filter(
              (item) => item[filterCols[i].FilterColumn].toString().indexOf(filterCols[i].FilterText) !== -1
            ).slice();
            break;
          case 'Equals':
            items = items.filter(
              (item) => item[filterCols[i].FilterColumn].toString()==filterCols[i].FilterText.toString()
            );
            break;
        }
      }
      
    }

    return items;

  }
}

@NgModule({
  declarations: [ myfilter ],
  exports: [ myfilter ]
})
export class MyPipesModule {}
